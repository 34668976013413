import i18next from 'i18next';
import {lazy} from 'react';
import {getExactHiddenRoute} from 'util/react';
import EN from './i18n/en.json';

export const LOGIN_PAGE = '/login';

i18next.addResourceBundle('en', 'login', EN)

export const MainLoginRoute = getExactHiddenRoute(
  'login',
  'menu.main',
  LOGIN_PAGE,
  lazy(() => import('./layout/MainLoginLayout')),
  null,
  []
);

export const ForgotPasswordRoute = getExactHiddenRoute(
  'login',
  'menu.forgotPassword',
  '/forgot-password',
  lazy(() => import('./layout/ForgotPasswordLayout')),
  null,
  []
);

export const ForgotPasswordIdRoute = getExactHiddenRoute(
  'login',
  'menu.forgotPassword',
  '/forgot-password/:id',
  lazy(() => import('./layout/ForgotPasswordIdLayout')),
  null,
  []
);

export default (
  <>
    {MainLoginRoute}
    {ForgotPasswordRoute}
    {ForgotPasswordIdRoute}
  </>
);
