import i18next from 'i18next';
import {lazy} from 'react';

import {
  getExactRoute,
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'materialOfProduct', EN);
i18next.addResourceBundle('lt', 'materialOfProduct', LT);

export const MaterialOfProductsListRoute = getExactRoute('materialOfProduct', 'menu.main', '/materialOfProduct', lazy(() => import('./layout/MaterialOfProductsListLayout')), null, ['admin']);
export const MaterialOfProductsCreateRoute = getHiddenRoute('materialOfProduct', 'menu.main', '/materialOfProduct/new', lazy(() => import('./layout/MaterialOfProductsEditLayout')), null, ['admin']);
export const MaterialOfProductsEditRoute = getHiddenRoute('materialOfProduct', 'menu.main', '/materialOfProduct/:id', lazy(() => import('./layout/MaterialOfProductsEditLayout')), null, ['admin']);


const MaterialOfProductsRoute = (
  <>
    {MaterialOfProductsListRoute}
    {MaterialOfProductsCreateRoute}
    {MaterialOfProductsEditRoute}
  </>
);

export default MaterialOfProductsRoute;
