import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'profileModel', EN);
i18next.addResourceBundle('lt', 'profileModel', LT);

export const ProfileModelsListRoute = getHiddenRoute('profileModel', 'menu.main', '/profileModel', lazy(() => import('./layout/ProfileModelsListLayout')), null, ['admin']);
export const ProfileModelsCreateRoute = getHiddenRoute('profileModel', 'menu.main', '/profileModel/new', lazy(() => import('./layout/ProfileModelsEditLayout')), null, ['admin']);
export const ProfileModelsEditRoute = getHiddenRoute('profileModel', 'menu.main', '/profileModel/:id', lazy(() => import('./layout/ProfileModelsEditLayout')), null, ['admin']);


const ProfileModelsRoute = (
  <>
    {ProfileModelsListRoute}
    {ProfileModelsCreateRoute}
    {ProfileModelsEditRoute}
  </>
);

export default ProfileModelsRoute;
