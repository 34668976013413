import i18next from '../../i18n';
import {lazy} from 'react';
import {getExactHiddenRoute, getExactRoute} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'order', EN);
i18next.addResourceBundle('lt', 'order', LT);

export const OrderNewRoute = getExactHiddenRoute('order', 'menu.edit', '/order/new', lazy(() => import('./layout/OrderEditLayout')), null, ['admin', 'customer']);
export const OrderEditRoute = getExactHiddenRoute('order', 'menu.edit', '/order/:id', lazy(() => import('./layout/OrderEditLayout')), null, ['admin', 'customer']);
export const OrderListRoute = getExactRoute('order', 'menu.list', '/order', lazy(() => import('./layout/OrderListLayout')), null, ['admin', 'customer']);
export const OrderListRouteAsDefault = getExactHiddenRoute('order', 'menu.list', '/', lazy(() => import('./layout/OrderListLayout')), null, ['admin', 'customer']);
export const OrderImportRoute = getExactHiddenRoute('order', 'menu.import', '/order/import/:id', lazy(() => import('./layout/OrderImportLayout')), null, ['admin', 'customer']);
export const OrderImportFormRoute = getExactRoute('order', 'menu.import', '/order/import-order', lazy(() => import('./layout/OrderImportFormLayout')), null, ['admin', 'customer']);
export const OrderSettingsRoute = getExactRoute('order', 'menu.settings', '/order/settings', lazy(() => import('./layout/OrderSettingsLayout')), null, ['admin', 'customer']);

const OrderRoute = (
  <>
    {OrderListRouteAsDefault}
    {OrderListRoute}
    {OrderEditRoute}
    {OrderNewRoute}
    {OrderImportRoute}
    {OrderImportFormRoute}
    {OrderSettingsRoute}
  </>
);

export default OrderRoute;
