import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';

i18next.addResourceBundle('en', 'ExportProfileType', EN);

export const URIS = {
  LIST: '/export-profile-type',
  EDIT: '/export-profile-type/:id',
  NEW: '/export-profile-type/new'
}

export const ExportProfileTypeListRoute = getHiddenRoute('ExportProfileType', 'menu.main', URIS.LIST, lazy(() => import('./layout/ExportProfileTypeListLayout')), null, ['admin']);
export const ExportProfileTypeCreateRoute = getHiddenRoute('ExportProfileType', 'menu.main', URIS.NEW, lazy(() => import('./layout/ExportProfileTypeEditLayout')), null, ['admin']);
export const ExportProfileTypeEditRoute = getHiddenRoute('ExportProfileType', 'menu.main', URIS.EDIT, lazy(() => import('./layout/ExportProfileTypeEditLayout')), null, ['admin']);

const ExportProfileTypeRoute = (
  <>
    {ExportProfileTypeListRoute}
    {ExportProfileTypeCreateRoute}
    {ExportProfileTypeEditRoute}
  </>
);

export default ExportProfileTypeRoute;
