import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';

i18next.addResourceBundle('en', 'ExportHandleColor', EN);

export const URIS = {
  LIST: '/export-handle-color',
  EDIT: '/export-handle-color/:id',
  NEW: '/export-handle-color/new'
}

export const ExportHandleColorListRoute = getHiddenRoute('ExportHandleColor', 'menu.main', URIS.LIST, lazy(() => import('./layout/ExportHandleColorListLayout')), null, ['admin']);
export const ExportHandleColorCreateRoute = getHiddenRoute('ExportHandleColor', 'menu.main', URIS.NEW, lazy(() => import('./layout/ExportHandleColorEditLayout')), null, ['admin']);
export const ExportHandleColorEditRoute = getHiddenRoute('ExportHandleColor', 'menu.main', URIS.EDIT, lazy(() => import('./layout/ExportHandleColorEditLayout')), null, ['admin']);

const ExportHandleColorRoute = (
  <>
    {ExportHandleColorListRoute}
    {ExportHandleColorCreateRoute}
    {ExportHandleColorEditRoute}
  </>
);

export default ExportHandleColorRoute;
