import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'VentModel', EN);
i18next.addResourceBundle('lt', 'VentModel', LT);

export const VentModelsListRoute = getHiddenRoute('VentModel', 'menu.main', '/ventModel', lazy(() => import('./layout/VentModelsListLayout')), null, ['admin']);
export const VentModelsCreateRoute = getHiddenRoute('VentModel', 'menu.main', '/ventModel/new', lazy(() => import('./layout/VentModelsEditLayout')), null, ['admin']);
export const VentModelsEditRoute = getHiddenRoute('VentModel', 'menu.main', '/ventModel/:id', lazy(() => import('./layout/VentModelsEditLayout')), null, ['admin']);


const VentModelsRoute = (
  <>
    {VentModelsListRoute}
    {VentModelsCreateRoute}
    {VentModelsEditRoute}
  </>
);

export default VentModelsRoute;
