import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'HandleColour', EN);
i18next.addResourceBundle('lt', 'HandleColour', LT);

export const HandleColoursListRoute = getHiddenRoute('HandleColour', 'menu.main', '/handleColour', lazy(() => import('./layout/HandleColoursListLayout')), null, ['admin']);
export const HandleColoursCreateRoute = getHiddenRoute('HandleColour', 'menu.main', '/handleColour/new', lazy(() => import('./layout/HandleColoursEditLayout')), null, ['admin']);
export const HandleColoursEditRoute = getHiddenRoute('HandleColour', 'menu.main', '/handleColour/:id', lazy(() => import('./layout/HandleColoursEditLayout')), null, ['admin']);


const HandleColoursRoute = (
  <>
    {HandleColoursListRoute}
    {HandleColoursCreateRoute}
    {HandleColoursEditRoute}
  </>
);

export default HandleColoursRoute;
