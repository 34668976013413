import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'ColourOfHingeCovers', EN);
i18next.addResourceBundle('lt', 'ColourOfHingeCovers', LT);

export const ColourOfHingeCoversesListRoute = getHiddenRoute('ColourOfHingeCovers', 'menu.main', '/colourOfHingeCovers', lazy(() => import('./layout/ColourOfHingeCoversesListLayout')), null, ['admin']);
export const ColourOfHingeCoversesCreateRoute = getHiddenRoute('ColourOfHingeCovers', 'menu.main', '/colourOfHingeCovers/new', lazy(() => import('./layout/ColourOfHingeCoversesEditLayout')), null, ['admin']);
export const ColourOfHingeCoversesEditRoute = getHiddenRoute('ColourOfHingeCovers', 'menu.main', '/colourOfHingeCovers/:id', lazy(() => import('./layout/ColourOfHingeCoversesEditLayout')), null, ['admin']);


const ColourOfHingeCoversesRoute = (
  <>
    {ColourOfHingeCoversesListRoute}
    {ColourOfHingeCoversesCreateRoute}
    {ColourOfHingeCoversesEditRoute}
  </>
);

export default ColourOfHingeCoversesRoute;
