import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'ColourOfVent', EN);
i18next.addResourceBundle('lt', 'ColourOfVent', LT);

export const ColourOfVentsListRoute = getHiddenRoute('ColourOfVent', 'menu.main', '/colourOfVent', lazy(() => import('./layout/ColourOfVentsListLayout')), null, ['admin']);
export const ColourOfVentsCreateRoute = getHiddenRoute('ColourOfVent', 'menu.main', '/colourOfVent/new', lazy(() => import('./layout/ColourOfVentsEditLayout')), null, ['admin']);
export const ColourOfVentsEditRoute = getHiddenRoute('ColourOfVent', 'menu.main', '/colourOfVent/:id', lazy(() => import('./layout/ColourOfVentsEditLayout')), null, ['admin']);


const ColourOfVentsRoute = (
  <>
    {ColourOfVentsListRoute}
    {ColourOfVentsCreateRoute}
    {ColourOfVentsEditRoute}
  </>
);

export default ColourOfVentsRoute;
