import {useAuth} from 'context/auth';
import {useEffect} from 'react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import {ForgotPasswordIdRoute, LOGIN_PAGE} from 'feature/login/routes';
import {UserInvitedRoute} from 'feature/user/routes';
import {caseMap} from '@s-e/frontend/flow-control';
import {identity} from 'crocks';

const AuthorizedOutlet = () => {
  const {isAuthorized} = useAuth();
  const nav = useNavigate();
  const l = useLocation();
  const isInviteRoute = l.pathname.match(/^\/invite\//i)?.length > 0;
  const isForgotPasswordRoute = l.pathname.match(/^\/forgot-password\//i)?.length > 0;
  const p = useParams();

  useEffect(() => {
    caseMap(identity, [
      [() => isInviteRoute, p => nav(UserInvitedRoute.props.path.replace(':id', p.id))],
      [() => isForgotPasswordRoute, p => nav(ForgotPasswordIdRoute.props.path.replace(':id', p.id))],
      [() => isAuthorized === false, () => nav(LOGIN_PAGE)],
    ], p);
  }, [isAuthorized]);

  if (isAuthorized === null) return null;

  return <Outlet/>
};

export default AuthorizedOutlet;
