import i18next from 'i18next';
import {lazy} from 'react';
import {getExactRoute} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'document', EN);
i18next.addResourceBundle('lt', 'document', LT);

export const DocumentListRoute = getExactRoute('document', 'menu.list', '/document', lazy(() => import('./layout/DocumentListLayout')), null, ['admin']);


const DocumentRoute = (
  <>
    {DocumentListRoute}
  </>
);

export default DocumentRoute;
