import {
  Maybe,
  safe,
  and,
  isObject,
  isTruthy,
  propSatisfies,
} from 'crocks';
import LogRocket from 'logrocket';
import {EyeIcon, RefreshIcon} from '@heroicons/react/solid';
import {createContextHook} from 'util/react';
import {useAuth} from './auth';
import {useCallback, useEffect, useRef, useState} from 'react';

const isTestingEnvironment = ['localhost', 'kodema.se.lt'].includes(window?.location?.hostname);
const isAllowedToWork = ['localhost', 'converter.kodema.lt', 'kodema.se.lt'].includes(window?.location?.hostname);
const isAllowedToAutostart = ['converter.kodema.lt'].includes(window?.location?.hostname);

const {
  LogRocketContext,
  LogRocketContextProvider,
  useLogRocket
} = createContextHook('LogRocket', ({
  appId = 'cxbxf4/kodema-converter'
}) => {
    const [isRecording, setIsRecording] = useState(false);
    const [sessionURL, setSessionURL] = useState(null);
    const timerRef = useRef();

    const start = useCallback(() => {
      if (!isAllowedToWork) return;
      isRecording
        ? LogRocket.startNewSession()
        : LogRocket.init(appId);
      setIsRecording(true);
    }, [appId, isRecording, setIsRecording]);

    const auth = useAuth();

    useEffect(() => {
      Maybe.of(auth?.userData)
        .chain(safe(and(isObject, propSatisfies('id', isTruthy))))
        .chain(safe(() => isAllowedToWork))
        .map(userData => LogRocket.identify(userData?.id, userData))
    }, [auth?.userData?.email]);

    useEffect(() => {
      if (isAllowedToWork && isAllowedToAutostart) start();
    }, []);

    useEffect(() => {
      if (!isAllowedToWork) return;

      const set = () => {
        Maybe.of(LogRocket.sessionURL)
          .chain(safe(value => String(value)?.match(/http/gi)))
          .map(setSessionURL)
          .map(() => clearInterval(timerRef.current))
      }

      timerRef.current = setInterval(set, 1000);
    }, []);

    return {
      sessionURL,
      start,
      isRecording,
      LogRocket,
    }
  });

const LogRocketToggle = () => {
  const {start, isRecording, sessionURL} = useLogRocket();
  const Icon = isRecording ? RefreshIcon : EyeIcon;

  if (!isAllowedToWork) return null;
  if (!isTestingEnvironment) return null;
  
  return (
    <div className='fixed bottom-0 right-0 z-20 ovelflow-hidden mx-2'>
      {sessionURL && (<code className='text-xs select-all leading none bg-gray-200 text-black rounded-lg px-3 py-1 ovelflow-hidden'>{sessionURL}</code>)}
      <button onClick={start}>
        <Icon className='w-8 h-8 text-red-500' style={{filter: 'drop-shadow(0px 0px 2px rgba(255, 0, 0, 0.3))'}} aria-hidden/>
      </button>
    </div>
  );
};

export {
  LogRocketContext,
  LogRocketContextProvider,
  useLogRocket,
  LogRocketToggle,
};
