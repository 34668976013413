import './i18n';
import './assets/tailwind.css';

import App from './App';
import {createRoot} from 'react-dom/client';
import {AuthContextProvider} from 'context/auth';
import {NotificationContextProvider} from 'feature/ui-notifications/context';
import {LogRocketContextProvider, LogRocketToggle} from 'context/logrocket';

const Root = createRoot(document.getElementById('root'));

Root.render(
  <NotificationContextProvider>
    <AuthContextProvider>
      <LogRocketContextProvider>
        <LogRocketToggle/>
        <App />
      </LogRocketContextProvider>
    </AuthContextProvider>
  </NotificationContextProvider>
);
