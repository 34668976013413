import i18next from 'i18next';
import {lazy} from 'react';
import {getExactRoute} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'report', EN);
i18next.addResourceBundle('lt', 'report', LT);

export const ReportListRoute = getExactRoute('report', 'menu.main', '/report', lazy(() => import('./layout/ReportListLayout')), null, ['admin']);

const ReportRoute = (
  <>
    {ReportListRoute}
  </>
);

export default ReportRoute;
