import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'BarArticle', EN);
i18next.addResourceBundle('lt', 'BarArticle', LT);

export const BarArticleListRoute = getHiddenRoute('BarArticle', 'menu.main', '/barArticle', lazy(() => import('./layout/BarArticleListLayout')), null, ['admin']);
export const BarArticleCreateRoute = getHiddenRoute('BarArticle', 'menu.main', '/barArticle/new', lazy(() => import('./layout/BarArticleEditLayout')), null, ['admin']);
export const BarArticleEditRoute = getHiddenRoute('BarArticle', 'menu.main', '/barArticle/:id', lazy(() => import('./layout/BarArticleEditLayout')), null, ['admin']);

const BarArticleRoute = (
  <>
    {BarArticleListRoute}
    {BarArticleCreateRoute}
    {BarArticleEditRoute}
  </>
);

export default BarArticleRoute;
