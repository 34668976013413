import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'GlassPackage', EN);
i18next.addResourceBundle('lt', 'GlassPackage', LT);

export const GlassPackagesListRoute = getHiddenRoute('GlassPackage', 'menu.main', '/glassPackage', lazy(() => import('./layout/GlassPackagesListLayout')), null, ['admin']);
export const GlassPackagesCreateRoute = getHiddenRoute('GlassPackage', 'menu.main', '/glassPackage/new', lazy(() => import('./layout/GlassPackagesEditLayout')), null, ['admin']);
export const GlassPackagesEditRoute = getHiddenRoute('GlassPackage', 'menu.main', '/glassPackage/:id', lazy(() => import('./layout/GlassPackagesEditLayout')), null, ['admin']);


const GlassPackagesRoute = (
  <>
    {GlassPackagesListRoute}
    {GlassPackagesCreateRoute}
    {GlassPackagesEditRoute}
  </>
);

export default GlassPackagesRoute;
