import ImportTemplate from 'feature/importTemplate';
import ClientFeature from 'feature/client/routes';
import ColorOfPanelsRoute from 'feature/colorOfPanel/routes';
import ColourOfHingeCoversesRoute from 'feature/colourOfHingeCovers/routes';
import ColourOfVentsRoute from 'feature/colourOfVent/routes';
import CompanyFeature from 'feature/company/routes';
import DocumentFeature from 'feature/document/routes';
import FrameColorsRoute from 'feature/frameColor/routes';
import GlassPackagesRoute from 'feature/glassPackage/routes';
import HandleColoursRoute from 'feature/handleColour/routes';
import LoginFeature from 'feature/login/routes';
import MaterialOfProductsFeature from 'feature/materialOfProduct/routes';
import ModelsRoute from 'feature/model/routes';
import MullionRoute from 'feature/mullion/routes';
import NameOfFittingsesFeature from 'feature/nameOfFittings/routes';
import FixedBarRoute from 'feature/fixedBar/routes';
import BarArticleRoute from 'feature/barArticle/routes';
import OrderFeature from 'feature/order/routes';
import ProfileModelsFeature from 'feature/profileModel/routes';
import ReportFeature from 'feature/report/routes';
import SashColorsRoute from 'feature/sashColor/routes';
import UserFeature from 'feature/user/routes';
import VentModelsRoute from 'feature/ventModel/routes';
import DeniedLayout from 'layout/DeniedLayout';
import {Route} from 'react-router-dom';
import NotFoundLayout from './layout/NotFoundLayout';
import ExportProfileTypeRoute from 'feature/exportProfileType/routes';
import ExportHandleColorRoute from 'feature/exportHandleColor/routes';

export {ImportTemplate};

const Routes = (
  <>
    {LoginFeature}
    {ModelsRoute}
    {OrderFeature}
    {DocumentFeature}
    {ClientFeature}
    {UserFeature}
    {CompanyFeature}
    {ReportFeature}
    {MaterialOfProductsFeature}
    {ProfileModelsFeature}
    {NameOfFittingsesFeature}
    {FrameColorsRoute}
    {SashColorsRoute}
    {ColorOfPanelsRoute}
    {GlassPackagesRoute}
    {VentModelsRoute}
    {ColourOfVentsRoute}
    {ColourOfHingeCoversesRoute}
    {HandleColoursRoute}
    {MullionRoute}
    {BarArticleRoute}
    {FixedBarRoute}
    {ExportProfileTypeRoute}
    {ExportHandleColorRoute}

    <Route isHidden path='/denied' element={<DeniedLayout />} />
    <Route isHidden path='*' element={<NotFoundLayout />} />
  </>
);

export default Routes;
