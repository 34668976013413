import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'SashColor', EN);
i18next.addResourceBundle('lt', 'SashColor', LT);

export const SashColorsListRoute = getHiddenRoute('SashColor', 'menu.main', '/sashColor', lazy(() => import('./layout/SashColorsListLayout')), null, ['admin']);
export const SashColorsCreateRoute = getHiddenRoute('SashColor', 'menu.main', '/sashColor/new', lazy(() => import('./layout/SashColorsEditLayout')), null, ['admin']);
export const SashColorsEditRoute = getHiddenRoute('SashColor', 'menu.main', '/sashColor/:id', lazy(() => import('./layout/SashColorsEditLayout')), null, ['admin']);


const SashColorsRoute = (
  <>
    {SashColorsListRoute}
    {SashColorsCreateRoute}
    {SashColorsEditRoute}
  </>
);

export default SashColorsRoute;
