import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'ColorOfPanel', EN);
i18next.addResourceBundle('lt', 'ColorOfPanel', LT);

export const ColorOfPanelsListRoute = getHiddenRoute('colorOfPanel', 'menu.main', '/colorOfPanel', lazy(() => import('./layout/ColorOfPanelsListLayout')), null, ['admin']);
export const ColorOfPanelsCreateRoute = getHiddenRoute('colorOfPanel', 'menu.main', '/colorOfPanel/new', lazy(() => import('./layout/ColorOfPanelsEditLayout')), null, ['admin']);
export const ColorOfPanelsEditRoute = getHiddenRoute('colorOfPanel', 'menu.main', '/colorOfPanel/:id', lazy(() => import('./layout/ColorOfPanelsEditLayout')), null, ['admin']);


const ColorOfPanelsRoute = (
  <>
    {ColorOfPanelsListRoute}
    {ColorOfPanelsCreateRoute}
    {ColorOfPanelsEditRoute}
  </>
);

export default ColorOfPanelsRoute;
