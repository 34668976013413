import i18next from 'i18next';
import {lazy} from 'react';

import {
  getExactRoute,
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'model', EN);
i18next.addResourceBundle('lt', 'model', LT);

export const ModelsListRoute = getExactRoute('model', 'menu.main', '/model', lazy(() => import('./layout/ModelsListLayout')), null, ['admin']);
export const ModelsCreateRoute = getHiddenRoute('model', 'menu.main', '/model/new', lazy(() => import('./layout/ModelsEditLayout')), null, ['admin']);
export const ModelsEditRoute = getHiddenRoute('model', 'menu.main', '/model/:id', lazy(() => import('./layout/ModelsEditLayout')), null, ['admin']);


const ModelsRoute = (
  <>
    {ModelsListRoute}
    {ModelsCreateRoute}
    {ModelsEditRoute}
  </>
);

export default ModelsRoute;
