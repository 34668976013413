import jwtDecode from 'jwt-decode';
import refresh from 'feature/login/api/refresh';
import useMergeReducer from 'hook/useMergeReducer';
import {api, apiQuery} from 'api';
import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {
  Maybe,
  and,
  chain,
  flip,
  getPathOr,
  getProp,
  isEmpty,
  isFunction,
  isString,
  isTruthy,
  map,
  not,
  objOf,
  option,
  pipe,
  safe,
  tap,
  tryCatch,
} from 'crocks';

/**
 * @typedef {object} AuthContextValue
 * @property {bool} isAuthorized: bool,
 * @property {(newState: object) => void} update
 * @property {(variables: object) => (query: string) => Async} api
 * @property {(query: string) => (variables: object) => Async} apiWithQuery
 * @property {(query: string) => Async} apiQuery
 */

const AuthContext = createContext();
const isValid = and(isString, not(isEmpty));

const AuthContextProvider = ({hasuraAdminSecret, children}) => {
  const [isAuthorized, setAuthorized] = useState(hasuraAdminSecret ? true : null);
  const [state, setState] = useMergeReducer({
    token: null,
    refreshToken: null
  });

  const userData = useMemo(() => pipe(
    getProp('token'),
    chain(safe(isTruthy)),
    chain(token => (
      tryCatch(() => jwtDecode(token))()
        .either(Maybe.Nothing, Maybe.Just)
    )),
    chain(getProp('userData')),
    option(null),
  )(state), [state]);

  // TODO: hard-coded application id
  const roles = useMemo(() => userData?.registrations?.find(r => r?.applicationId === 'fe74ec6e-e828-487b-984c-3c6e52f541b2')?.roles, [userData]);
  const isAdmin = useMemo(() => roles?.includes('admin'), [roles]);

  /**
   * @type {(variables: Object) => (query: string) => Async}
   */
  const authorizedApi = useMemo(() => {
    if (hasuraAdminSecret) return api({'x-hasura-admin-secret': hasuraAdminSecret})
    return pipe(
      getProp('token'),
      chain(safe(isValid)),
      map(pipe(
        str => `Bearer ${str}`,
        objOf('Authorization'),
        api
      )),
      option(apiQuery)
    )(state)
  }, [state]);

  const fail = useCallback(err => {
    setAuthorized(false);
    setState({token: null, refreshToken: null});
  }, [setAuthorized, setState]);

  const success = useCallback(() => setAuthorized(true), [setAuthorized]);

  const logout = useCallback((callback) => {
    setAuthorized(false);
    setState({token: null, refreshToken: null, user: null});
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    isFunction(callback) && callback();
  }, [state, setAuthorized]);

  useEffect(() => {
    if (hasuraAdminSecret) return;
    getProp('refreshToken', state)
      .chain(safe(isValid))
      .map(tap(value => localStorage.setItem('refreshToken', value)))
      .alt(safe(isValid, localStorage.getItem('refreshToken')))
      .either(fail, refreshToken => {
        if (state.token) return success();
        refresh(refreshToken)
          .map(tap(setState))
          .fork(fail, success);
      });
  }, [state, fail, success]);

  return (
    <AuthContext.Provider value={{
      ...state,
      isAuthorized,
      update: setState,
      api: authorizedApi,
      apiWithQuery: flip(authorizedApi),
      apiQuery: authorizedApi(null),
      logout,
      userData,
      roles,
      isAdmin,
      company_id: getPathOr(null, ['data', 'company_id'], userData),
    }}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * @type {() => AuthContextValue}
 * @throws {Error}
 */
const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) throw new Error('useAuth must be used within a AuthProvider');
  return context;
}

export {
  AuthContextProvider,
  useAuth,
};

