import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'FixedBar', EN);
i18next.addResourceBundle('lt', 'FixedBar', LT);

export const FixedBarListRoute = getHiddenRoute('FixedBar', 'menu.main', '/fixedBar', lazy(() => import('./layout/FixedBarListLayout')), null, ['admin']);
export const FixedBarCreateRoute = getHiddenRoute('FixedBar', 'menu.main', '/fixedBar/new', lazy(() => import('./layout/FixedBarEditLayout')), null, ['admin']);
export const FixedBarEditRoute = getHiddenRoute('FixedBar', 'menu.main', '/fixedBar/:id', lazy(() => import('./layout/FixedBarEditLayout')), null, ['admin']);

const FixedBarRoute = (
  <>
    {FixedBarListRoute}
    {FixedBarCreateRoute}
    {FixedBarEditRoute}
  </>
);

export default FixedBarRoute;
