import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'Mullion', EN);
i18next.addResourceBundle('lt', 'Mullion', LT);

export const MullionListRoute = getHiddenRoute('Mullion', 'menu.main', '/mullion', lazy(() => import('./layout/MullionListLayout')), null, ['admin']);
export const MullionCreateRoute = getHiddenRoute('Mullion', 'menu.main', '/mullion/new', lazy(() => import('./layout/MullionEditLayout')), null, ['admin']);
export const MullionEditRoute = getHiddenRoute('Mullion', 'menu.main', '/mullion/:id', lazy(() => import('./layout/MullionEditLayout')), null, ['admin']);

const MullionRoute = (
  <>
    {MullionListRoute}
    {MullionCreateRoute}
    {MullionEditRoute}
  </>
);

export default MullionRoute;
