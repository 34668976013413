import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'FrameColor', EN);
i18next.addResourceBundle('lt', 'FrameColor', LT);

export const FrameColorsListRoute = getHiddenRoute('FrameColor', 'menu.main', '/frameColor', lazy(() => import('./layout/FrameColorsListLayout')), null, ['admin']);
export const FrameColorsCreateRoute = getHiddenRoute('FrameColor', 'menu.main', '/frameColor/new', lazy(() => import('./layout/FrameColorsEditLayout')), null, ['admin']);
export const FrameColorsEditRoute = getHiddenRoute('FrameColor', 'menu.main', '/frameColor/:id', lazy(() => import('./layout/FrameColorsEditLayout')), null, ['admin']);


const FrameColorsRoute = (
  <>
    {FrameColorsListRoute}
    {FrameColorsCreateRoute}
    {FrameColorsEditRoute}
  </>
);

export default FrameColorsRoute;
