import i18next from 'i18next';
import {lazy} from 'react';

import {
  getHiddenRoute
} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';

i18next.addResourceBundle('en', 'NameOfFittings', EN);
i18next.addResourceBundle('lt', 'NameOfFittings', LT);

export const NameOfFittingsesListRoute = getHiddenRoute('NameOfFittings', 'menu.main', '/nameOfFittings', lazy(() => import('./layout/NameOfFittingsesListLayout')), null, ['admin']);
export const NameOfFittingsesCreateRoute = getHiddenRoute('NameOfFittings', 'menu.main', '/nameOfFittings/new', lazy(() => import('./layout/NameOfFittingsesEditLayout')), null, ['admin']);
export const NameOfFittingsesEditRoute = getHiddenRoute('NameOfFittings', 'menu.main', '/nameOfFittings/:id', lazy(() => import('./layout/NameOfFittingsesEditLayout')), null, ['admin']);


const NameOfFittingsesRoute = (
  <>
    {NameOfFittingsesListRoute}
    {NameOfFittingsesCreateRoute}
    {NameOfFittingsesEditRoute}
  </>
);

export default NameOfFittingsesRoute;
